<template>
   <v-tooltip color="secondary" left>
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" style="cursor: pointer;">save_alt</v-icon>
        </template>
        <span>{{ $t('dashboard.download') }}</span>
    </v-tooltip>
</template>

<script>
export default {

}
</script>
