<template>
    <v-card :class="{
        'mb-4': $vuetify.breakpoint.xs,
        'ml-3 mr-3': $vuetify.breakpoint.smAndDown,
        'mt-3 ml-3': $vuetify.breakpoint.mdAndUp, 
        'pa-2' : true, 
        'body-2' : true, 
        'd-flex align-center justify-space-between' : true }" elevation="2">
        <div :class="{
            'd-flex' : true ,
            'flex-column' : $vuetify.breakpoint.mdAndUp,
            }">
            <v-card-text :class="{
                'd-flex' : true,
                'flex-column' : $vuetify.breakpoint.mdAndUp, 
                'pa-0' : true ,
                'justify-center' : $vuetify.breakpoint.smAndDown,
                'align-center': $vuetify.breakpoint.smAndDown,
                'align-start': $vuetify.breakpoint.mdAndUp,
                'justify-start': $vuetify.breakpoint.mdAndUp
                }">
                <v-chip small :medium="$vuetify.breakpoint.mdAndUp" :class="{'ma-2' : true}" color="green" text-color="white"> {{ $t('dashboard.present') }} - {{ presentStudents.length }} </v-chip>
            </v-card-text>
            <v-card-text :class="{
                'd-flex pa-0' : true, 
                'flex-column' : $vuetify.breakpoint.mdAndUp, 
                'justify-center' : $vuetify.breakpoint.smAndDown,
                'align-center': $vuetify.breakpoint.smAndDown,
                'align-start': $vuetify.breakpoint.mdAndUp,
                'justify-start': $vuetify.breakpoint.mdAndUp
                }">
                <v-chip small :class="{'ma-2' : true}" color="warning" text-color="white"> {{ $t('dashboard.ill') }}  - {{ illStudents.length }} </v-chip>
                <v-chip small :class="{'ma-2' : true}" color="error" text-color="white"> {{ $t('dashboard.absent') }} - {{ illStudents.length }} </v-chip>
                <v-chip small :class="{'ma-2' : true}" color="primary" text-color="white"> {{ $t('dashboard.unExcused') }} - {{ unExcusedStudents.length }} </v-chip>
            </v-card-text>
            <v-card-text :class="{ 'd-md-none' : true }">
                <v-tooltip color="primary" left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" color="info" v-on="on" @click="() => $refs.bsheet.toggle()" style="cursor: pointer;">info</v-icon>
                    </template>
                    <span>{{ $t('dashboard.info') }}</span>
                </v-tooltip>
            </v-card-text>
            <bottomsheet ref="bsheet" />
            <v-tooltip color="lightgray" left>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" color="lightgray" v-on="on" @click="() => $refs.chartModal.toggleDialog()" style="cursor: pointer;">analytics</v-icon>
                </template>
                <span>{{ $t('dashboard.chart') }}</span>
            </v-tooltip>
            <downloadreport />
            <chartmodal ref="chartModal"/>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Bottomsheet from "../components/Bottomsheet";
import Downloadreport from "../components/Downloadreport";
import Chartmodal from "../components/Chartmodal";

export default {
    name: 'Informationcard',
    components: { Bottomsheet, Downloadreport, Chartmodal },
    computed: {
        ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            dayInfo: 'dashboardOnline/dayInfo',
        })
    }
}
</script>