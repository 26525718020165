<template>
  <v-dialog v-model="dialog" width="500">
        <chart-bar/>
    </v-dialog>
</template>

<script>
import ChartBar from './Charts/Donut/ChartBar.vue';
export default {
    components: {ChartBar},
    data: () => ({
        dialog: false
    }),
    methods: {
        toggleDialog() {
            this.dialog = !this.dialog;
        }
    }
}
</script>

<style>

</style>