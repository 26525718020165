var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'mb-4': _vm.$vuetify.breakpoint.xs,
    'ml-3 mr-3': _vm.$vuetify.breakpoint.smAndDown,
    'mt-3 ml-3': _vm.$vuetify.breakpoint.mdAndUp, 
    'pa-2' : true, 
    'body-2' : true, 
    'd-flex align-center justify-space-between' : true },attrs:{"elevation":"2"}},[_c('div',{class:{
        'd-flex' : true ,
        'flex-column' : _vm.$vuetify.breakpoint.mdAndUp,
        }},[_c('v-card-text',{class:{
            'd-flex' : true,
            'flex-column' : _vm.$vuetify.breakpoint.mdAndUp, 
            'pa-0' : true ,
            'justify-center' : _vm.$vuetify.breakpoint.smAndDown,
            'align-center': _vm.$vuetify.breakpoint.smAndDown,
            'align-start': _vm.$vuetify.breakpoint.mdAndUp,
            'justify-start': _vm.$vuetify.breakpoint.mdAndUp
            }},[_c('v-chip',{class:{'ma-2' : true},attrs:{"small":"","medium":_vm.$vuetify.breakpoint.mdAndUp,"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.present'))+" - "+_vm._s(_vm.presentStudents.length)+" ")])],1),_c('v-card-text',{class:{
            'd-flex pa-0' : true, 
            'flex-column' : _vm.$vuetify.breakpoint.mdAndUp, 
            'justify-center' : _vm.$vuetify.breakpoint.smAndDown,
            'align-center': _vm.$vuetify.breakpoint.smAndDown,
            'align-start': _vm.$vuetify.breakpoint.mdAndUp,
            'justify-start': _vm.$vuetify.breakpoint.mdAndUp
            }},[_c('v-chip',{class:{'ma-2' : true},attrs:{"small":"","color":"warning","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.ill'))+" - "+_vm._s(_vm.illStudents.length)+" ")]),_c('v-chip',{class:{'ma-2' : true},attrs:{"small":"","color":"error","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.absent'))+" - "+_vm._s(_vm.illStudents.length)+" ")]),_c('v-chip',{class:{'ma-2' : true},attrs:{"small":"","color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.unExcused'))+" - "+_vm._s(_vm.unExcusedStudents.length)+" ")])],1),_c('v-card-text',{class:{ 'd-md-none' : true }},[_c('v-tooltip',{attrs:{"color":"primary","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"info"},on:{"click":function () { return _vm.$refs.bsheet.toggle(); }}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.info')))])])],1),_c('bottomsheet',{ref:"bsheet"}),_c('v-tooltip',{attrs:{"color":"lightgray","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"lightgray"},on:{"click":function () { return _vm.$refs.chartModal.toggleDialog(); }}},'v-icon',attrs,false),on),[_vm._v("analytics")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.chart')))])]),_c('downloadreport'),_c('chartmodal',{ref:"chartModal"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }