<template>
      <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
      </template>
      <v-sheet class="pa-2" height="390px">
        <v-container>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.dayOfWeek') }}</v-col>
                <v-col>{{ dayInfo[0] }}</v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.currentDate') }}</v-col>
                <v-col>{{ dayInfo[1] }} {{ dayInfo[2] }}</v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.institutionName') }}</v-col>
                <v-col>ABC</v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.classGroupName') }} </v-col>
                <v-col>XYZ</v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.totalStudents') }} </v-col>
                <v-col><v-chip small color="success">{{ students.length }}</v-chip></v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.presentStudents') }} </v-col>
                <v-col><v-chip small color="primary">{{ presentStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.absentStudents') }} </v-col>
                <v-col><v-chip small color="error">{{ absentStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.unwellStudents') }} </v-col>
                <v-col><v-chip small color="warning">{{ illStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.unExcusedStudents') }} </v-col>
                <v-col><v-chip small color="info">{{ unExcusedStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row no-gutters class="pa-1" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.loggedOutStudents') }} </v-col>
                <v-col><v-chip small color="secondary">{{ loggedOutStudents.length }}</v-chip></v-col>
            </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Bottomsheet',
    data:() => ({
        sheet: false
    }),
    methods : {
        toggle() {
            this.sheet = !this.sheet;
        }
    },
    computed: {
        ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            dayInfo: 'dashboardOnline/dayInfo',
        })
    }
}
</script>
